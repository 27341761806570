<template>
  <div class="container">
    <div class="col-lg-6 mx-auto">
      <h1>Belépés</h1>
      <form method="get" action="/api/login">
        <input type="submit" value="Belépés">
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
};
</script>
